<template>
  <div>
    <div class="remaining-kino-coin px-4 py-3 d-flex justify-content-between">
      <div class="title">{{ $t('purchase.checkout.yourRemainingPoint')}}</div>
      <div class="value">{{ toCurrency(remainingKinoCoin) }}</div>
    </div>
    <template>
      <a-row class="checkout-cart">
        <a-col
          :span="24"
          class="checkout-cart__content px-2 py-4 mb-1"
        >
          <!-- merah disini adalah eslint -->
          <div
            v-for="(cart, index) in carts"
            :key="index"
          >
            <a-row
              class="mb-3"
              :key="`${index}-warehouse`"
            >
              <a-col
                :xs="2"
                :sm="2"
                :md="2"
                class="text-center"
              >
                <pin-icon color="var(--biz-secondary-100)"/>
              </a-col>
              <a-col
                :xs="22"
                :sm="22"
                :md="22"
              >
                <div class="warehouse-name"> {{ cart.name }} </div>
                <div class="warehouse-address"> {{ [cart.address.district, cart.address.city].join(' - ') }} </div>
              </a-col>
            </a-row>

            <div
              :key="`${index}`"
              style="height: 1px; background: #F4F4F4"
              class="mb-3"
            />
            <!-- This is Item Lists on checkout -->
            <a-row :key="`${index}-content`">
              <a-col
                :xs="{ span: 22, offset: 2 }"
                :sm="{ span: 22, offset: 2 }"
                :md="22"
                class="mb-2"
              >
                <a-row
                  class="mb-3"
                  v-for="(item, index) in cart.cart_list"
                  :key="index"
                >
                  <a-col :span="6">
                    <img
                      style="width: 68.3px; height: 66.51px;"
                      :src="item.showimg_url"
                    />
                  </a-col>
                  <a-row :span="18">
                    <a-col :span="9">
                      <div class="product-title">
                        {{ item.catalog_title }}
                      </div>
                      <div class="product-size mt-1">
                        {{ item.quantity }} {{ $t('purchase.checkout.item') }} ({{ item.uom }}) x {{
                            toCurrency(item.price.selling_price)
                        }}
                      </div>
                    </a-col>
                    <a-col
                      :span="9"
                      class="product-align"
                    >
                      <div class="product-total d-flex align-items-center justify-content-end">
                        <WalletOutlined color="var(--biz-brand-button)"/>
                        <div class="ml-2">{{ toCurrency(item.price.selling_price * item.quantity) }}</div>
                      </div>
                    </a-col>
                  </a-row>
                </a-row>
              </a-col>
              <a-divider class="my-3"/>
              <div class="px-3 d-flex justify-content-between sub-total">
                <div class="title">SubTotal</div>
                <div class="value">{{ toCurrency(totalPrice) }}</div>
              </div>
            </a-row>
            <!-- End of Item Lists on checkout -->
          </div>
        </a-col>
      </a-row>
    </template>
    <div class="payment-kino-coin px-4 py-3 d-flex justify-content-between">
      <div class="title">Bayar dengan Coin</div>
    </div>
  </div>
</template>

<script>
import PinIcon from '@/components/Icons/Pin.vue'
import WalletOutlined from '@/components/Icons/WalletOutlined.vue'

export default {
  props: {
    carts: {
      type: Array,
      default: () => [],
    },
    paymentList: {
      type: [Array, Object],
      default: () => [],
    },
    pointUsed: {
      type: Number,
      default: () => 0,
    },
    grossAmount: {
      type: Number,
      default: () => 0,
    },
    sellerId: {
      type: String,
      default: () => '',
    },
    grandTotal: {
      type: Number,
      default: () => 0,
    },
    voucherOnFaktur: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PinIcon,
    WalletOutlined,
  },
  data: function () {
    return {
      payment: 'Term of Payment',
      poNumber: null,
      modalVisible: false,
      modalVisibleMixPayment: false,
      showModalVoucher: false,
      activeKey: [],
      cbd_tf_bank: [],
      cbd_va: [],
      cod: [],
      cad: [],
      selected_method: '',
      selected_mix_method: '',
      data_method: '',
      data_mix_method: '',
      remainingPrice: 0,
      seen: false,
      seen_balance: false,
      seen_points: false,
      loyaltyPoints: 0,
      sum: 0,
      rewardAmountsApplied: [0],
      availablePoint: 0,
      size: 'large',
      x: 'one',
      selectedMethod: '',
      chooseOption: null,
      data: {
        payment_method: [],
      },
      typePaymentMap: {
        COD: 'Melalui Kurir',
        BANK_TRANSFER: 'Transfer Bank (Verifikasi Manual)',
        VA: 'Virtual Account',
        CAD: 'Pembayaran Tempo',
        EWALLET: 'E-Wallet',
      },
      tempUsedPoints: 0,
      invalidStatusCredit: false,
      selectedStatusCredit: null,
      mixPaymentMethod: [],
      mixPaymentList: [],
      maximumVAAmount: [],
      voucherUsed: [],
      currency: 0,
    }
  },
  watch: {
    sum: {
      deep: true,
      immediate: true,
    },
    x: {
      deep: true,
      immediate: true,
      handler() {
        if (this.x === 'one') {
          this.$store.commit('loyalty/SET_LOYALTY_STORE', {
            usedPoints: Number(this.availablePoint),
          })
        } else {
          this.$store.commit('loyalty/SET_LOYALTY_STORE', {
            usedPoints: null,
          })
          this.loyaltyPoints = 0
        }
      },
    },
    pointUsed(value) {
      if (value) {
        if (value > this.totalPrice) {
          this.$emit('emitLocalUsedPoints', this.totalPrice)
          this.tempUsedPoints = this.totalPrice
        } else {
          this.$emit('emitLocalUsedPoints', value)
          this.tempUsedPoints = value
        }
      } else {
        this.$emit('emitLocalUsedPoints', 0)
        this.tempUsedPoints = 0
      }
    },
  },
  computed: {
    // ...mapState(['dashboard']),
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    totalPrice() {
      if (!this.carts.length) {
        return 0
      }
      const warehouse_id = this.carts[0].id
      const { cart_list } = this.carts.find((item) => item.id === warehouse_id)
      const price = cart_list.reduce((acc, current) => {
        return acc + current.quantity * current.price.selling_price
      }, 0)
      return price || 0
    },
    getCreditTermLimit() {
      return `${this.data.credit_limit.term_value ?? ''} ${this.data.credit_limit.term_unit ?? '-'}`
    },
    getCreditBonds() {
      this.validationCreditLimit()
      return this.data.credit_limit.bond_type ?? '-'
    },
    getCreditBalance() {
      return `${Intl.NumberFormat('en-US').format(this.data.credit_limit.balance || 0)}`
    },
    getMultipleBondAllowed() {
      return this.data.credit_limit.multiple_bond_allowed ?? 0
    },
    getCreditUsage() {
      return this.data.credit_limit.credit_limit_usage ?? 0
    },
    remainingKinoCoin() {
      return this.availablePoint - this.totalPrice
    },
  },
  mounted() {
    this.getPaymentMethod()
    this.getLoyaltyCoin()
  },
  methods: {
    getLoyaltyCoin() {
      this.$store
        .dispatch('loyalty/GETLOYALTY', {})
        .then(({ data }) => {
          this.availablePoint = data.available_point
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    isMaxVAAmount(method) {
      const total = this.totalPrice ?? 0
      const findValue = this.maximumVAAmount.find((obj) => obj.va_name.toUpperCase() === method.toUpperCase())
      return findValue ? Boolean(total >= findValue.va_max_amount) : false
    },
    isMaxVAAmountMixPayment(method) {
      const total = this.remainingPrice ?? 0
      const findValue = this.maximumVAAmount.find((obj) => obj.va_name.toUpperCase() === method.toUpperCase())
      return findValue ? Boolean(total >= findValue.va_max_amount) : false
    },
    valueMaxVAAmount(method) {
      const findValue = this.maximumVAAmount.find((obj) => obj.va_name.toUpperCase() === method.toUpperCase())
      return findValue ? this.toCurrency(findValue.va_max_amount) : 0
    },
    getMaximumVAAmount() {
      this.$store.dispatch('meta/MAXIMUM_VA_AMOUNT').then(res => {
        const tempArrMaxVA = []
        Object.entries(res).forEach(([key, value]) => {
          tempArrMaxVA.push({
            va_name: key,
            va_max_amount: value,
          })
        })
        this.maximumVAAmount = tempArrMaxVA
      }).catch(err => {
        console.log(err)
      })
    },
    handleChangeLoyalty(e) {
      const sumLoyalty = e.target.value
      // alert(sumLoyalty)
      this.$store.commit('loyalty/SET_LOYALTY_STORE', {
        usedPoints: Number(sumLoyalty),
      })
    },
    handleCancel() {
      this.modalVisible = false
    },
    handleCancelMixPayment() {
      this.modalVisibleMixPayment = false
    },
    getPaymentMethod() {
      const channel_id = this.$store.state.app.channel_id
      const seller_id = this.sellerId
      this.$store
        .dispatch('purchase/GETPAYMENTMETHOD', {
          seller_id,
          channel_id,
        })
        .then(({ data }) => {
          this.data = data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setSum() {
      return (this.sum = parseFloat(
        this.rewardAmountsApplied.reduce((a, b) => a + b),
      ).toFixed(0))
    },
    applyDiscount() {
      var amount = this.loyaltyPoints
      setTimeout(() => {
        if (Number(amount) > Number(this.availablePoint)) {
          this.seen_balance = true
        } else {
          // push in the amount as an int
          this.rewardAmountsApplied.push(+amount)

          // get the last discount in the array
          this.availablePoint = Number(this.availablePoint) - Number(amount)
          this.availablePoint = parseFloat(this.availablePoint).toFixed(0)
          this.seen_balance = false
          this.loyaltyPoints = 0
          this.setSum()
        }
        this.$store.commit('loyalty/SET_LOYALTY_STORE', {
          usedPoints: Number(this.sum),
        })
      }, 1000)
    },
    removeDiscount() {
      // reset the balance
      this.availablePoint = Number(this.availablePoint) + Number(this.sum)
      // empty the total rewards array
      this.rewardAmountsApplied = [0]
      this.seen_balance = false
      this.setSum()
    },
    handleSelectPayment(value) {
      this.modalVisible = !this.modalVisible
      let selectedPayment
      let selectedPaymentImage
      ;(this.paymentList.payment_method || []).forEach((list) => {
        list.payment_method.forEach((item) => {
          if (item.id === value) {
            selectedPayment = {
              term: list.payment_term,
              method_id: item.id,
              method: item.method,
              bank: item.bank_name,
              number: item.account_number,
              payment_name: item.payment_name,
              payment_type: list.payment_type,
              pg_channel_id: item.pg_channels_id,
            }
            selectedPaymentImage = {
              term: list.payment_term,
              method_id: item.id,
              method: item.method,
              bank: item.bank_name,
              number: item.account_number,
              image: item.image,
              type: list.payment_type,
              payment_name: item.payment_name,
              payment_type: list.payment_type,
              pg_channel_id: item.pg_channels_id,
            }
          }
        })
      })
      if (selectedPayment !== undefined) {
        this.data_method = selectedPaymentImage
        if (selectedPaymentImage?.term === 'CAD') {
          this.remainingPrice = this.grandTotal
          const creditUsed = this.selectedStatusCredit === 0 ? this.data.credit_limit.balance : this.totalPrice
          this.$emit('creditUsed', creditUsed)
          this.$emit('handleCurrentTotalCreditLimit', this.data.credit_limit.balance)
        } else {
          this.data_mix_method = ''
          this.$emit('creditUsed', 0)
          this.$emit('handleCurrentTotalCreditLimit', 0)
          this.remainingPrice = 0
        }
        this.$emit('setSelectedPayment', selectedPayment)
        this.onFilterMixPayment(selectedPayment.term)
      }
    },
    onFilterMixPayment(value) {
      this.mixPaymentMethod = this.data.payment_method.filter((obj) => {
        return obj.payment_type !== value
      })
    },
    handleSelectMixPayment(value) {
      this.modalVisibleMixPayment = !this.modalVisibleMixPayment
      let selectedPayment
      let selectedPaymentImage
      ;(this.paymentList.payment_method || []).forEach((list) => {
        list.payment_method.forEach((item) => {
          if (item.id === value) {
            selectedPayment = {
              term: list.payment_term,
              method_id: item.id,
              method: item.method,
              bank: item.bank_name,
              number: item.account_number,
              payment_name: item.payment_name,
              payment_type: list.payment_type,
              pg_channel_id: item.pg_channels_id,
            }
            selectedPaymentImage = {
              term: list.payment_term,
              method_id: item.id,
              method: item.method,
              bank: item.bank_name,
              number: item.account_number,
              image: item.image,
              type: list.payment_type,
              payment_name: item.payment_name,
              payment_type: list.payment_type,
              pg_channel_id: item.pg_channels_id,
            }
          }
        })
      })
      if (selectedPayment !== undefined) {
        this.data_mix_method = selectedPaymentImage
        this.$emit('setSelectedMixPayment', selectedPayment)
      }
    },
    onChange(e) {
      this.seen = !this.seen
      if (this.seen) {
        this.$store.commit('loyalty/SET_LOYALTY_STORE', {
          usedPoints: Number(this.availablePoint),
        })
      } else {
        this.$store.commit('loyalty/SET_LOYALTY_STORE', {
          usedPoints: null,
        })
      }
    },
    onChangeSelectPoint() {
      this.seen_points = !this.seen_points
    },
    validationCreditLimit() {
      const creditLimit = this.data.credit_limit
      if (creditLimit.is_valid === false) {
        this.invalidStatusCredit = true
        // single bond
        if (creditLimit.multiple_bond_allowed === 1) {
          if (creditLimit.balance === 0) {
            this.selectedStatusCredit = 1
          } else {
            if (creditLimit.multiple_bond_allowed === creditLimit.credit_limit_usage) {
              this.selectedStatusCredit = 2
            } else {
              this.selectedStatusCredit = 1
            }
          }
        // double bond
        } else {
          if (creditLimit.balance === 0) {
            this.selectedStatusCredit = 1
          } else {
            if (creditLimit.multiple_bond_allowed === creditLimit.credit_limit_usage) {
              this.selectedStatusCredit = 3
            } else {
              this.selectedStatusCredit = 2
            }
          }
        }
      } else {
        this.invalidStatusCredit = false
        if (creditLimit.balance < this.grandTotalBeforeCreditLimit) {
          this.selectedStatusCredit = 0
        } else {
          this.selectedStatusCredit = null
        }
      }
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    emitShowModalVoucher(val) {
      this.showModalVoucher = val
    },
    emitSubmitModalVoucher(val) {
      this.$emit('emitSelectedVoucher', val)
      this.voucherUsed = val.filter((obj) => obj.checked === true)

      this.selected_method = ''
      this.selected_mix_method = ''
      this.showModalVoucher = false
    },
  },
}
</script>
<style lang="scss" scoped>
.form-select-coin {
  margin-top: 20px;
  height: 35px;
  width: 275px;
  font-size: 20px;
}
.checkout-cart {
  margin-bottom: 1rem;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  &__poin {
    background: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
  }

  &__poin2 {
    background: #ffffff;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }

    .content {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: var(--biz-secondary-100);
      margin-left: 10px;
      vertical-align: top;
    }
  }

  &__calculation {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    font-style: normal;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .content {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: var(--biz-secondary-100);
    }
  }

  &__pay-method {
    background: #ffffff;
    align-items: center;
    color: #000000;
    font-style: normal;

    .span.ant-radio {
      float: right;
    }

    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Open Sans';
    }

    .subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      font-family: 'Open Sans';
    }

    .title2 {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      font-family: 'Open Sans';
    }

    .list-card {
      background: #f6f6f6;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    .confirm-payment {
      height: 33px;
      background: #265c9b;
      border-radius: 5px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  &__content {
    background: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .warehouse-name {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #015ca1;
    }

    .warehouse-address {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #585858;
    }

    .product-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }

    .product-total {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: var(--biz-secondary-100);
      margin-right: 10px;
    }

    .product-align {
      text-align: right;
    }

    .product-size {
      font-style: normal;
      font-weight: normal;
      color: #929292;
    }

    .product-price {
      &__discount {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: line-through;
        color: #585858;
      }

      &__selling {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
    }

    .payment-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }

    .payment-list {
      height: 35px;
      background: #265c9b;
      border-radius: 8px;
      color: #ffffff;
      padding: 0.5rem;
      cursor: pointer;
    }
  }

  .ant-cascader-picker {
    background: #e8f6fe;
    border: 1px solid #265c9b;
    box-sizing: border-box;
    border-radius: 5px;
    color: #265c9b;

    ::placeholder {
      color: #265c9b !important;
    }
  }

  .payment-method {
    background: #e8f6fe;
    border: 1px solid #265c9b;
    box-sizing: border-box;
    border-radius: 10px;
    color: #265c9b;
    height: 40px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    &__calculation {
      background: #e8f6fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #015ca1;
      font-style: normal;

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

      .content {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: var(--biz-secondary-100);
      }
    }
  }

  .mix-payment-method {
    background: #F4F4F4;
    border: 1px solid #015CA1;
    box-sizing: border-box;
    border-radius: 10px;
    color: #000000;
    height: 40px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    &__calculation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-style: normal;

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

      .content {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: var(--biz-secondary-100);
      }
    }
  }

  .voucher {
    background: #fff2e6;
    border: 1px solid var(--biz-secondary-100);
    box-sizing: border-box;
    border-radius: 10px;
    color: var(--biz-secondary-100);
    height: 40px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    &__calculation {
      background: #fff2e6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--biz-secondary-100);
      font-style: normal;

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }

      .content {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: var(--biz-secondary-100);
      }
    }
  }
}
.list-card {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.list-card2 {
  background: #f6f6f6;
  padding: 10px;
  border-radius: 10px;
}
select {
  padding: 10px;
  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"/></svg>');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

/* For IE (thanks to @SaiManoj) */
select::-ms-expand {
  display: none;
}
// Force to style CSS (Not recommended) For Select Coin Box
select#list-coin {
  background-color: #265c9b !important;
  color: #e8f6fe;
  border-radius: 5px !important;
  height: 40px;
  width: 275px;
  line-height: 20px;
  outline: none; /*Add this*/
}
.pointer {
  cursor: pointer;
}
.sub-total {
  .title {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  .value {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: right;
    color: var(--biz-secondary-100);
  }
}
.remaining-kino-coin {
  margin-bottom: 1rem;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .title {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
  }

  .value {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #767676;
  }
}
.payment-kino-coin {
  margin-bottom: 1rem;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .title {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--biz-secondary-100);
  }
}
</style>
