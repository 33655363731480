<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width || 12" :height="height || 19" viewBox="0 0 12 19" fill="none" b>
    <path d="M5.21866 0.0375195C4.04982 0.20805 3.01599 0.701875 2.16689 1.50123C0.649888 2.92942 0.0175077 5.08591 0.529097 7.07542C0.696074 7.72556 0.870156 8.09505 1.37819 8.88375C3.91837 12.8201 5.68051 15.5024 5.76223 15.5628C5.89367 15.6587 6.10684 15.6587 6.23829 15.5628C6.32 15.5024 8.08214 12.8201 10.6223 8.88375C11.1304 8.09505 11.3044 7.72201 11.4714 7.07542C11.848 5.5975 11.6029 3.98102 10.8035 2.69494C9.9402 1.30583 8.65057 0.403448 7.08028 0.0943632C6.5829 -0.00511265 5.70183 -0.0299816 5.21866 0.0375195ZM7.01988 1.3911C7.33962 1.48702 7.61318 1.74992 7.74108 2.08388C7.77305 2.16914 7.79437 2.46757 7.80503 3.03245L7.81924 3.86023H8.25267C8.91702 3.86023 9.15861 3.9597 9.35401 4.32208C9.43572 4.47129 9.45348 4.54945 9.45348 4.72353C9.44638 5.11433 9.03071 8.77361 8.97742 8.90506C8.90281 9.0827 8.7536 9.24612 8.56175 9.35981L8.39833 9.45573L6.07486 9.46639C3.51337 9.47705 3.55955 9.4806 3.28955 9.25678C3.00533 9.0223 3.00178 9.00809 2.76375 6.8516C2.64651 5.78224 2.54703 4.8159 2.54703 4.70577C2.54703 4.553 2.56835 4.46063 2.64651 4.32563C2.8419 3.96325 3.08704 3.86023 3.74784 3.86023H4.18127L4.19548 3.03245C4.21325 2.15138 4.22391 2.07677 4.40865 1.81032C4.5401 1.61492 4.71773 1.48347 4.9451 1.40531C5.10142 1.34847 5.25774 1.34136 5.98605 1.33781C6.64329 1.33781 6.88133 1.34847 7.01988 1.3911Z" :fill="color || '#F5831F'"/>
    <path d="M5.27878 2.42506C5.26456 2.43927 5.25391 2.76967 5.25391 3.15692V3.86035H5.99997H6.74604L6.73894 3.13915L6.72828 2.42151L6.01418 2.41085C5.62339 2.4073 5.29299 2.4144 5.27878 2.42506Z" :fill="color || '#F5831F'"/>
    <path d="M3.63362 5.05703C3.64428 5.13164 3.72599 5.85639 3.81481 6.6664C3.90363 7.47642 3.98534 8.20117 3.996 8.27222L4.01731 8.40723H5.99972H7.98568L8.007 8.28999C8.01765 8.22959 8.09581 7.50484 8.18463 6.68417C8.27345 5.86349 8.35516 5.13164 8.36582 5.05703L8.38713 4.92558H5.99972H3.6123L3.63362 5.05703Z" :fill="color || '#F5831F'"/>
    <path d="M3.5097 15.4426C1.89677 15.6558 0.592933 16.0714 0.138188 16.512C-0.0501055 16.6932 -0.0465528 16.782 0.152398 16.9632C0.649777 17.4215 1.96428 17.8265 3.61983 18.0325C6.867 18.434 10.7643 17.9331 11.8301 16.9774C12.0433 16.7855 12.0539 16.7003 11.8728 16.5262C11.3931 16.0608 10.1106 15.6522 8.46572 15.4426C7.63439 15.336 7.73386 15.3005 7.41057 15.7979C7.25425 16.0359 7.06595 16.2775 6.98424 16.345C6.38739 16.8282 5.60579 16.8282 5.01605 16.345C4.93433 16.2775 4.74249 16.0324 4.58972 15.7979L4.31261 15.3716L4.15274 15.3751C4.06392 15.3787 3.77615 15.4071 3.5097 15.4426Z" :fill="color || '#F5831F'"/>
  </svg>
</template>

<script>
export default {
  name: 'PinIcon',
  props: ['width', 'height', 'color'],
}
</script>

<style>

</style>
