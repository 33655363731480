<template>
  <div class="checkout">
    <div class="ant-modal-root order-detail_loader" v-if="loading">
      <div class="ant-modal-mask">
        <div class="ant-modal-wrap d-flex justify-content-center align-items-center">
          <a-spin></a-spin>
        </div>
      </div>
    </div>

    <b-modal hide-footer hide-header centered v-model="modalError.visible" size="md">
      <div class="d-flex flex-column align-items-center text-center">
        <div class="mb-1">
          <Warning />
        </div>
        <span style="color: #015289" class="font-weight-bold mb-2">
          {{ $t('menu.purchase.checkout.order_failed') }}
        </span>
        <span class="mb-2">{{ modalError.message }}</span>
        <button class="btn-cart mb-1" @click="$router.push({ path: '/purchase/cart' })">
          {{ $t('manu.purchase.checkout.back_to_cart') }}
        </button>
        <span v-if="modalError.type === 'rejected'" style="color: red">
          {{ $t('manu.purchase.checkout.auto_delete') }}
        </span>
      </div>
    </b-modal>

    <template v-if="!loading">
      <a-row class="mb-3" id="checkoutTitle">
        <a-col :span="24" class="d-flex justify-content-start align-items-center mb-3">

          <div class="ml-2">
            <h3>
              {{ $t('purchase.checkout.title') }}
            </h3>
          </div>
        </a-col>
      </a-row>

      <a-row class="mb-3" v-if="carts.length">
        <a-col class="mb-3 mr-md-2" :xs="24" :sm="24" :md="15">
          <ShippingComponent
            :selectedShippingAddress="selectedShippingAddress"
            :listAvailableShippingAddress="listAvailableShippingAddress"
            :selectedShippingType="selectedShippingType"
            @setSelectedShippingAddress="setSelectedShippingAddress"
            @fetchShippingAddress="fetchShippingAddress"
            ref="shippingComponent" id="shippingComponent" />
          <!-- Ini Voucher -->
          <CartComponent
            :carts="carts"
            ref="cartComponent"
            id="cartComponent"
            :currency="currency"
            :sellerId="sellerId"
            :grandTotal="grandTotal"
            :grandTotalBeforeCreditLimit="grandTotalBeforeCreditLimit"
            :voucherOnFaktur="voucherOnFaktur"
            @emitSelectedVoucher="onSelectedVoucherOnFaktur"
          />
          <BillingComponent :selectedBillingAddress="selectedBillingAddress"
            :listAvailableBillingAddress="listAvailableBillingAddress"
            @setSelectedBillingAddress="setSelectedBillingAddress" @fetchBillingAddress="fetchBillingAddress"
            :selectedShippingAddress="selectedShippingAddress" ref="billingComponent" id="billingComponent" />
          <!-- <PointComponent /> -->
        </a-col>

        <a-col class="mb-3 ml-md-2" :xs="24" :sm="24" :md="8">
          <CalculationComponent
            ref="calculation"
            :creditLimitUsage="creditLimitUsage"
            :carts="carts"
            :dataDiscount="dataDiscount"
            :dataTransactionType="dataTransactionType"
            @setVisibleModalConfirmCheckout="setVisibleModalConfirmCheckout"
            @handleCheckoutPage="checkout"
            :selectedPayment="selectedPayment"
            :selectedShippingAddress="selectedShippingAddress"
            :depositReturn="depositReturn"
            @handleGrandTotal="handleGrandTotal"
            @handleGrandTotalBeforeCreditLimit="handleGrandTotalBeforeCreditLimit"
            :grandTotal="grandTotal"
            :currentTotalCreditLimit="currentTotalCreditLimit"
            :selectedVoucherOnFaktur="selectedVoucherOnFaktur"
          />
        </a-col>
      </a-row>

      <a-row class="mb-3" v-if="!carts.length">
        <h1 class="mx-auto d-flex justify-content-center">
          {{ $t('purchase.checkout.cart_empty') }}
        </h1>
        <h5 class="mx-auto d-flex justify-content-center">
          {{ $t('purchase.checkout.select_product') }} <span class="ml-2" style="color: blue; cursor: pointer;"
            @click.prevent="() => $router.push({ path: '/purchase/catalogue' })"> {{ $t('purchase.checkout.here') }}
          </span>
        </h5>
      </a-row>
    </template>
  </div>
</template>

<script>
import ShippingComponent from '@/components/Purchase/Checkout/Shipping'
import BillingComponent from '@/components/Purchase/Checkout/Billing'
import CartComponent from '@/views/loyalty-redeem/purchase/checkout-cart.vue'
import Warning from '@/components/Icons/Warning.vue'
import CalculationComponent from '@/views/loyalty-redeem/purchase/checkout-calculation.vue'
import { getIdWarehouse, joinWarehouseCart } from '@/utils/purchase'
import getPromotionPerProduct from '@/utils/discount/PerProduct'
import { getPromotionPerTransaction } from '@/utils/discount/PerTransaction'

export default {
  components: {
    ShippingComponent,
    BillingComponent,
    CartComponent,
    CalculationComponent,
    Warning,
  },
  data: function () {
    return {
      listAvailableShippingAddress: [],
      selectedShippingAddress: null,
      listAvailableBillingAddress: [],
      selectedBillingAddress: null,
      carts: [],
      cartTemp: null,
      selectedMixPayment: null,
      selectedPayment: null,
      visibleModalConfirmCheckout: false,
      loading: false,
      sellerId: '',
      sum: 0,
      cartCompo: this.$refs,
      modalError: {
        visible: false,
        message: '',
        type: '',
      },
      localPointUsed: 0,
      creditLimitUsage: 0,
      businessId: '',
      customerId: '',
      dataDiscount: [],
      dataVoucher: [],
      dataPercentage: [],
      dataTransactionType: [],
      depositReturn: 0,
      depositReturnUsed: 0,
      grandTotal: 0,
      voucherOnFaktur: [],
      selectedVoucherOnFaktur: [],
      currentTotalCreditLimit: 0,
      currentTotalCoin: 0,
      currentTotalDepositReturn: 0,
      grandTotalBeforeCreditLimit: 0,
      promotions: {},
      selectedShippingType: 'shipping',
    }
  },
  watch: {
    cartCompo: {
      deep: true,
      immediate: true,
    },
  },
  computed: {
    totalPriceProduct() {
      return this.$refs?.calculation?.totalPriceProduct
    },
    shippingCost() {
      return 0
    },
    insuranceCost() {
      return 0
    },
    promotion() {
      return 0
    },
    grossAmount() {
      return getPromotionPerTransaction(this.totalPriceProduct, this.dataDiscount) + this.shippingCost + this.insuranceCost
    },
    totalPrice() {
      return this.grossAmount - this.promotion
    },
  },
  methods: {
    handleGrandTotal(val) {
      this.grandTotal = val
    },
    handleGrandTotalBeforeCreditLimit(val) {
      this.grandTotalBeforeCreditLimit = val
    },
    fetchShippingAddress({ addr_key }) {
      this.$store.dispatch('purchase/GETSHIPPINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        addr_key,
      })
        .then(({ data }) => {
          if (data && data.length && addr_key === 'last') {
            this.selectedShippingAddress = data[0]
          }

          if (data && data.length && addr_key === 'all') {
            this.listAvailableShippingAddress = data
          }

          if (!this.selectedShippingAddress) {
            this.$refs.shippingComponent.$data.isCreateNewAddress = true
          }
        })
        .catch(() => {
          this.$refs.shippingComponent.$data.isCreateNewAddress = true
        })
    },
    setSelectedShippingAddress(value) {
      this.selectedShippingAddress = value
    },
    fetchBillingAddress({ addr_key }) {
      this.$store.dispatch('purchase/GETBILLINGADDRESS', {
        channel_id: this.$store.state.user.user_data.channel_id,
        addr_key,
      })
        .then(({ data }) => {
          if (data && data.length && addr_key === 'last') {
            this.selectedBillingAddress = data[0]
          }

          if (data && data.length && addr_key === 'all') {
            this.listAvailableBillingAddress = data
          }

          if (!this.selectedBillingAddress) {
            this.$refs.billingComponent.$data.isCreateNewAddress = true
          }
        })
        .catch(() => {
          this.$refs.billingComponent.$data.isCreateNewAddress = true
        })
    },
    setSelectedBillingAddress(value) {
      this.selectedBillingAddress = value
    },
    getBillingAddressDataForm(param) {
      const form = this.$refs.billingComponent.$refs.billingAddressForm.$data.form

      return form.getFieldValue(param)
    },
    getShippingAddressDataForm(param) {
      const form = this.$refs.shippingComponent.$refs.shippingAddressForm.$data.form

      return form.getFieldValue(param)
    },
    getCountryLabel(value, addressType) {
      if (addressType === 'billing') {
        const findData = this.$refs.billingComponent.$refs.billingAddressForm.$data.countryList.find(item => +item.value === +value)
        return findData ? findData.label : undefined
      }

      if (addressType === 'shipping') {
        const findData = this.$refs.shippingComponent.$refs.shippingAddressForm.$data.countryList.find(item => +item.value === +value)
        return findData ? findData.label : undefined
      }
    },
    getCiyId(label, addressType) {
      if (addressType === 'billing') {
        const findData = this.$refs.billingComponent.$refs.billingAddressForm.$data.cityList.find(item => item.label === label)
        return findData ? findData.value : undefined
      }

      if (addressType === 'shipping') {
        const findData = this.$refs.shippingComponent.$refs.shippingAddressForm.$data.cityList.find(item => item.label === label)
        return findData ? findData.value : undefined
      }
    },
    getAreaId(label, addressType) {
      if (addressType === 'billing') {
        const findData = this.$refs.billingComponent.$refs.billingAddressForm.$data.areaList.find(item => item.label === label)
        return findData ? findData.value : undefined
      }

      if (addressType === 'shipping') {
        const findData = this.$refs.shippingComponent.$refs.shippingAddressForm.$data.areaList.find(item => item.label === label)
        return findData ? findData.value : undefined
      }
    },
    setVisibleModalConfirmCheckout(value) {
      if (this.$refs.shippingComponent.$data.isCreateNewAddress) {
        const element = document.getElementById('shippingComponent')
        element.scrollIntoView({
          behavior: 'smooth',
        })

        return this.$notification.warning({
          message: this.$t('purchase.checkout.confirmShipping'),
        })
      }

      if (this.$refs.billingComponent.$data.isCreateNewAddress) {
        const element = document.getElementById('billingComponent')
        element.scrollIntoView({
          behavior: 'smooth',
        })

        return this.$notification.warning({
          message: this.$t('purchase.checkout.confirmBilling'),
        })
      }

      /** Checking selected payment */
      if (!this.selectedPayment && value) {
        const element = document.getElementById('cartComponent')
        element.scrollIntoView({
          behavior: 'smooth',
        })

        return this.$notification.warning({
          message: this.$t('purchase.checkout.confirmPaymentMethods'),
        })
      }

      if (!value) {
        const element = document.getElementById('checkoutTitle')
        element.scrollIntoView({
          behavior: 'smooth',
        })
      }
      this.visibleModalConfirmCheckout = value
    },
    // will be used later
    retry(data, ms = 2000, maxRetries = 5) {
      const varData = data
      return new Promise((resolve, reject) => {
        var retries = 0
        this.getDetailOrder(data.id)
          .then(({ data }) => {
            const status = data.order_state.toLowerCase()
            ++retries
            if (retries < maxRetries) {
              if (status === 'created') {
                setTimeout(() => {
                  this.retry(varData)
                }, ms)
              } else if (status === 'rejected') {
                this.modalError = {
                  visible: true,
                  message: data.reason,
                  type: 'rejected',
                }
              } else {
                this.$notification.close('1')
                this.$notification.success({
                  message: 'checkout success',
                })
                this.$router.push({ path: `/loyalty-redeem/how-to-pay-detail/${varData.id}` })
                resolve(data)
              }
            } else {
              this.$notification.close('1')
              this.$notification.error({
                message: 'checkout failed',
                description: data && data.message ? data.message : 'Rejected',
              })
            }
          }).catch((err) => {
            this.$notification.close('1')
            reject(err)
          })
      })
    },
    async getDetailOrder(order_id) {
      return await this.$store.dispatch('purchase/GETDETAIL', { order_id, channel_id: this.$store.state.app.redeem_channel_id })
    },
    checkout() {
      this.$notification.open({
        key: '1',
        message: 'Proses Membuat Pesanan',
        icon: () => <a-spin></a-spin>,
        description: 'Kino Siap sedang memproses pesanan anda',
      })
      const customer_data = {
        id: this.$store.state.user.user_data.buyer_id,
        entity_name: this.$store.state.user.user_data.store_name,
        first_name: this.$store.state.user.user_data.first_name,
        last_name: this.$store.state.user.user_data.last_name,
        email: this.$store.state.user.user_data.email,
        phone: this.$store.state.user.user_data.phone,
      }
      const transaction_data = {
        payment_method: null,
        sub_total: (this.totalPriceProduct - this.localPointUsed),
        shipping_cost: this.shippingCost, // refer to shipment_data shipping_cost
        insurance_cost: this.insuranceCost, // refer to shipment_data insurance_cost
        gross_amount: this.grossAmount, // gross_amout sub_total + shipping cost + insurance
        total: this.totalPrice, // total setelah dikurangi promotion * apakah dikurangi produknya atau setelah ditambah sipping cost dll
        currency: 'IDR',
        loyalty_usage: null,
        deposit: this.depositReturnUsed,
      }
      const billing_data = {
        id: this.selectedBillingAddress.id,
        first_name: this.selectedBillingAddress.first_name,
        last_name: this.selectedBillingAddress.last_name,
        phone: this.selectedBillingAddress.phone,
        address: {
          line1: this.selectedBillingAddress.address_line1,
          line2: this.selectedBillingAddress.address_line2,
          postal_code: this.selectedBillingAddress.postal_code,
          country: this.selectedBillingAddress.country,
          province: this.selectedBillingAddress.province,
          city: this.selectedBillingAddress.province,
          district: this.selectedBillingAddress.province,
          sub_district: this.selectedBillingAddress.province,
          country_id: +this.selectedBillingAddress.country_id,
          city_id: +this.selectedBillingAddress.city_id,
          sub_district_id: +this.selectedBillingAddress.area_id,
        },
      }
      const destination_data = {
        id: this.selectedShippingAddress.id,
        first_name: this.selectedShippingAddress.first_name,
        last_name: this.selectedShippingAddress.last_name,
        phone: this.selectedShippingAddress.phone,
        address: {
          line1: this.selectedShippingAddress.address_line1,
          line2: this.selectedShippingAddress.address_line2,
          postal_code: this.selectedShippingAddress.postal_code,
          country: this.selectedShippingAddress.country,
          province: this.selectedShippingAddress.province,
          city: this.selectedShippingAddress.province,
          district: this.selectedShippingAddress.province,
          sub_district: this.selectedShippingAddress.province,
          country_id: +this.selectedShippingAddress.country_id,
          city_id: +this.selectedShippingAddress.city_id,
          sub_district_id: +this.selectedShippingAddress.area_id,
        },
      }
      const shipping_data = {
        courier: 'Kurir Distributor', // hardcode
        service_code: 'OWN', // hardcode Own Courier
        service_type: '', // hardcode
        service_name: '', // hardcode
        shipping_cost: this.shippingCost, // hardcode
        insurance_cost: this.insuranceCost, // hardcode
      }
      let currency = 'Koin'
      this.cartTemp.cart_list.map(item => {
        if (item.price.currency) {
          currency = item.price.currency
        }
      })
      const payment = {
        term: null,
        payment_type: null,
        method_id: null,
        method: null,
        bank: null,
        number: null,
        term_days: null,
        pg_channel_id: null,
        payment_name: null,
        mix_payment_method_id: null,
        gross_amount: {
          value: this.grossAmount,
          currency,
        },
        credit_limit: null,
        callback_url: null,
      }
      const cart_id = this.cartTemp.id
      const warehouse_id = this.$route.query.warehouse_id
      const seller_id = this.carts[0].seller_id // dari warehouse besar
      const po_number = this.$refs.cartComponent.$data.poNumber || ''
      const carts = this.cartTemp.cart_list.map(item => {
        return {
          id: item.id,
          item_id: item.item_id,
          catalog: {
            sku: item.catalog_sku,
            product_name: item.catalog_title,
            uom: item.uom,
          },
          price: {
            ...item.price, // kurang data
            selling_price: getPromotionPerProduct(item, this.dataDiscount),
          },
          image_url: item.showimg_url,
          qty: item.quantity,
          status: 'OPEN',
        }
      })
      this.$store.dispatch('purchase/CHECKOUT', {
        customer_data,
        transaction_data,
        billing_data,
        destination_data,
        shipping_data,
        promotions: {},
        payment,
        cart_id,
        warehouse_id,
        seller_id,
        po_number,
        carts,
        channel_id: this.$store.state.app.redeem_channel_id,
        pg_channel_id: null,
      })
        .then(async ({ data }) => {
          this.retry(data)
        })
        .catch((err) => {
          const { data } = err.response
          this.modalError = {
            visible: true,
            message: this.$t(data.message_code) || data.message,
            // message: data &&
            //   data.message === 'Out of stock item' ? 'Stok sudah habis, silahkan hapus produk pada halaman keranjang'
            //   :  'Stok tidak cukup, silahkan ubah kuanti produk pada halaman keranjang',
            type: 'error',
          }
          this.$notification.close('1')
        })
    },
    onSelectedVoucherOnFaktur(val) {
      this.selectedVoucherOnFaktur = val.filter((obj) => obj.checked === true)
      console.log(val.filter((obj) => obj.checked === true))
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
  },
  mounted() {
    this.loading = true
    this.$store.dispatch('purchase/GETCART', {
      channel_id: this.$store.state.app.redeem_channel_id,
    })
      .then((response) => {
        const data = response.data
        if (data && data.id && Array.isArray(data.cart_list) && data.cart_list.length) {
          this.businessId = response.business_id
          this.cartTemp = { ...data }
          const warehouse_ids = this.$route.query.warehouse_id ? [this.$route.query.warehouse_id] : getIdWarehouse(this.cartTemp.cart_list).length ? getIdWarehouse(this.cartTemp.cart_list) : []
          this.$router.push({ query: { warehouse_id: warehouse_ids[0] } })
          return this.$store.dispatch('warehouse/GETWAREHOUSELIST', { id: warehouse_ids })
        }
      })
      .then(async ({ data }) => {
        this.carts = joinWarehouseCart(data, this.cartTemp.cart_list)
        this.sellerId = data[0].seller_id
        this.customerId = data[0].id
        return this.fetchShippingAddress({ addr_key: 'last' })
      })
      .then(() => {
        return this.fetchBillingAddress({ addr_key: 'last' })
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.loading = false
      })
  },
}
</script>

<style lang="scss">
.checkout {
  &-icon {
    background: #fff !important;
    padding: 6px 10px;
    border-radius: 50%;
  }
}

.btn-cart {
  // height: 33px;
  background: #265c9b;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  border: none;
  width: 275px;
  padding: 5px;
}
</style>
