<template>
  <a-row class="checkout-calculation px-4 py-4 mt-2">
    <a-col class="title mb-3">
      {{ $t('purchase.checkout.order_summary') }}
    </a-col>

    <div class="mt-3 d-flex justify-content-between align-items-center text-dark">
      <div> {{ $t('purchase.checkout.total_product') }} ({{ carts ? carts[0].cart_list.length : 0 }} {{
          $t('purchase.checkout.item')
      }}) </div>
      <div style="color: var(--biz-secondary-100)"> {{ toCurrency(totalPriceProduct) }} </div>
    </div>
    <!-- Slicing AT-209  -->
    <!-- <div class="mt-3 d-flex justify-content-between align-items-center text-dark">
      <div> Promo Agustus - September </div>
      <div style="color: #FF0000"> - {{ toCurrency(totalPriceProduct) }} </div>
    </div>
    <div class="mt-3 d-flex justify-content-between align-items-center text-dark">
      <div> Promo Diskon Pronas Kino </div>
      <div style="color: #FF0000"> - {{ toCurrency(totalPriceProduct) }} </div>
    </div> -->
    <!-- End of slicing  -->
    <div v-if="dataTransactionType.length > 0" >
      <div v-for="(item, index) in dataTransactionType" :key="index">
        <div v-if="item.type === 'transaction'" class="mt-3 d-flex justify-content-between align-items-center text-dark">
          <div> {{ item.name }} </div>
          <div style="color: #FF0000"> - {{ toCurrency(discountMapping(totalPriceProduct, dataDiscount, item.promotion_id, index)) }} </div>
        </div>
      </div>
    </div>

    <div v-if="voucherUsed && voucherUsed.length > 0" >
      <div v-for="(item, index) in voucherUsed" :key="index">
        <div class="mt-3 d-flex justify-content-between align-items-center text-dark">
          <div> {{ item.name }} </div>
          <div style="color: #FF0000"> - {{ toCurrency(item.value) }} </div>
        </div>
      </div>
    </div>
    <div class="mt-3 d-flex justify-content-between align-items-center text-dark" v-if="tempUsedPoints">
      <div> {{ $t('purchase.checkout.point_used') }} </div>
      <div style="color: var(--biz-secondary-100)">- {{Math.round(tempUsedPoints) ? toCurrency(Math.round(tempUsedPoints)) : 0 }} </div>
    </div>
    <div
      class="mt-3 d-flex justify-content-between align-items-center text-dark"
      v-if="tempCreditLimit > 0"
    >
      <div> Credit Limit </div>
      <div class="text-dark">- {{ toCurrency(tempCreditLimit) }} </div>
    </div>
    <div class="mt-3 d-flex justify-content-between align-items-center text-dark" v-if="tempUsedPoints">
      <div> {{ $t('purchase.checkout.point_used') }} </div>
      <div style="color: var(--biz-secondary-100)">- {{Math.round(tempUsedPoints) ? toCurrency(Math.round(tempUsedPoints)) : 0 }} </div>
    </div>
    <div v-if="(tempUsedDepositReturn > 0)">
      <div class="mt-3 d-flex justify-content-between align-items-center text-dark">
        <div> {{ $t('purchase.checkout.return_balance') }} </div>
        <div> - {{ toCurrency(tempUsedDepositReturn) }} </div>
      </div>
    </div>
    <a-divider />

    <div class="mt-3 d-flex justify-content-between align-items-center">
      <div class="text-dark"> {{ $t('purchase.checkout.grand_total') }} </div>
      <div class="total"> {{ toCurrency(totalPayment) }} <!-- {{withPoints(totalPriceProduct)}} --></div>
    </div>

    <a-col
      :span="24"
      v-if="Boolean(selectedShippingAddress)"
      class="confirm-payment mt-4 d-flex align-items-center justify-content-center"
      @click.prevent="handleCheckoutPage"
    >
      {{ $t('purchase.checkout.confirm_order') }}
    </a-col>
    <a-col
      :span="24"
      v-else
      class="button-disabled mt-4 d-flex align-items-center justify-content-center"
    >
      {{ $t('purchase.checkout.confirm_order') }}
    </a-col>
    <a-col
      :span="24"
      class="back-button bg-white text- mt-4 mb-2 d-flex align-items-center justify-content-center"
      @click.prevent="$router.go(-1)"
    >
      {{ $t('customerService.back') }}
    </a-col>
  </a-row>
</template>

<script>
import { getPromotionPerTransaction, getmappingTransaction } from '@/utils/discount/PerTransaction'
export default {
  props: [
    'carts',
    'subTotal',
    'pointUsed',
    'selectedPayment',
    'creditLimitUsage',
    'dataDiscount',
    'dataTransactionType',
    'selectedShippingAddress',
    'depositReturn',
    'grandTotal',
    'currentTotalCreditLimit',
    'selectedVoucherOnFaktur',
  ],
  data() {
    return {
      tempUsedPoints: 0,
      tempUsedDepositReturn: 0,
      tempCreditLimit: 0,
      currentPoints: 0,
      currentCreditLimit: 0,
      currentDepositReturn: 0,
      totalPayment: 0,
      voucherUsed: [],
    }
  },
  computed: {
    totalPriceProduct() {
      if (!this.carts.length) {
        return 0
      }
      const warehouse_id = this.carts[0].id
      const { cart_list } = this.carts.find((item) => item.id === warehouse_id)
      const price = cart_list.reduce((acc, current) => {
        return acc + current.quantity * current.price.selling_price
      }, 0)

      return price || 0
    },
  },
  methods: {
    discountTransaction(total, discount) {
      return getPromotionPerTransaction(total, discount)
    },
    discountMapping(total, discount, id, index) {
      const map = getmappingTransaction(total, discount, id)
      if (map.length > 0) {
        return map[index].discount
      }
    },
    // withPoints(total) {
    //   console.log({ total, pointUsed: this.pointUsed })
    // },
    fixedVoucher(item) {
      if (item?.value_type) {
        return item?.value_type
      } else {
        return null
      }
    },
    applyVoucherOnFaktur(total, listVoucher) {
      let currentTotal = total
      // eslint-disable-next-line
      let tempVoucherUsed = []
      listVoucher.map((obj, index) => {
        if (this.fixedVoucher(obj) === 'fixed') {
          currentTotal = currentTotal - obj.value
          tempVoucherUsed.push({
            name: obj.name,
            value: obj.value,
          })
        } else {
          const totalVoucherValue = (currentTotal * obj.value / 100)
          if (totalVoucherValue > obj?.max_value) {
            currentTotal = currentTotal - obj.max_value
            tempVoucherUsed.push({
              name: obj.name,
              value: obj.max_value,
            })
          } else {
            currentTotal = currentTotal - totalVoucherValue
            tempVoucherUsed.push({
              name: obj.name,
              value: totalVoucherValue,
            })
          }
        }
      })
      this.voucherUsed = tempVoucherUsed
      return currentTotal
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    handleCheckoutPage() {
      this.$emit('handleCheckoutPage')
    },
    handleBalanceUsed() {
      let grand_total = this.applyVoucherOnFaktur(this.discountTransaction(this.totalPriceProduct, this.dataDiscount), this.selectedVoucherOnFaktur)
      this.tempUsedPoints = 0
      this.tempUsedDepositReturn = 0
      this.tempCreditLimit = 0

      if (this.currentPoints > 0) {
        let reduce = 0
        if (this.currentPoints > grand_total) {
          reduce = grand_total
        } else {
          reduce = this.currentPoints
        }
        this.tempUsedPoints = reduce
        grand_total = grand_total - reduce
      }
      if (this.currentCreditLimit > 0) {
        let reduce = 0
        if (this.currentCreditLimit > grand_total) {
          reduce = grand_total
        } else {
          reduce = this.currentCreditLimit
        }
        this.tempCreditLimit = reduce
        grand_total = grand_total - reduce
      }
      if (this.currentDepositReturn > 0) {
        let reduce = 0
        if (this.currentDepositReturn > grand_total) {
          reduce = grand_total
        } else {
          reduce = this.currentDepositReturn
        }
        this.tempUsedDepositReturn = reduce
        grand_total = grand_total - reduce
      }
      this.totalPayment = grand_total
      this.$emit('handleGrandTotal', grand_total)
      this.$emit('handleGrandTotalBeforeCreditLimit', this.applyVoucherOnFaktur(this.discountTransaction(this.totalPriceProduct, this.dataDiscount), this.selectedVoucherOnFaktur))
    },
  },
  watch: {
    depositReturn(value) {
      this.currentDepositReturn = value
      this.handleBalanceUsed()
    },
    dataTransactionType(value) {
      this.handleBalanceUsed()
    },
    grandTotal(value) {
      this.handleBalanceUsed()
    },
    selectedVoucherOnFaktur: {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.handleBalanceUsed()
      },
    },
    pointUsed(value) {
      this.currentPoints = value
      this.handleBalanceUsed()
    },
    creditLimitUsage(value) {
      this.currentCreditLimit = value
      this.handleBalanceUsed()
    },
  },
  mounted() {
    this.handleBalanceUsed()
  },
}
</script>

<style lang="scss" scoped>
.checkout-calculation {
  background: #ffffff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: #000000;
  }

  .point {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--biz-secondary-100);
  }

  .total {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: var(--biz-secondary-100);
  }

  .term-payment {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
  }

  .confirm-payment {
    height: 33px;
    background: var(--biz-brand-button);
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
  }

  .back-button {
    height: 33px;
    color: var(--biz-brand-button);
    border-radius: 5px;
    border: solid 1px var(--biz-brand-button);
    background: #ffffff;
    cursor: pointer;
  }

  .button-disabled {
    height: 33px;
    border-radius: 5px;
    background: #ababab;
    color: white;
    cursor: not-allowed;
  }
}
</style>
